import React, { useEffect, useLayoutEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import {
  AdminPage,
  Dashboard,
  ProductGallery,
  ErrorReset,
  ForgotPass,
  Home,
  Login,
  NewPassword,
  SuccessReset,
  TenderResults,
  Terms,
  UserAccount,
  P403,
  P404,
  DiamondVideo,
} from './pages'
import { ROUTES } from './utils/routes'
import { LoggedInWrapper } from './wrappers/LoggedInWrapper'
import { AppStore } from './store/applicationState'
import { checkToken, ReturnType } from './utils/checkToken'
import { SetIsAuth } from './store/auth/actions'
import { GlobalLoader } from './components/GlobalLoader'
import { setStore } from './utils/functions'
import { useLoadApp } from './hooks/useLoadApp'
import { C } from './pages/C'
import { useLoginFromWorthy } from './hooks/useLoginFromWorthy'
import { SetIsWorthy } from './store/worthyState/action'
import { ENVIRONMENT } from './utils/consts'

export const App: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { loginFromWorthy } = useLoginFromWorthy()
  const { worthy_auth_token } = queryString.parse(location.search)

  const { signIn, terms, userData, worthyState } = useSelector((store: AppStore) => store)

  useLayoutEffect(() => {
    ;(async () => {
      const userToken: ReturnType = await checkToken()
      if (userToken.success) {
        dispatch(SetIsAuth())
        setStore(userToken, dispatch)
      } else if (worthy_auth_token) {
        loginFromWorthy(worthy_auth_token as string)
      } else if (
        location.pathname !== ROUTES.newPass &&
        location.pathname !== ROUTES.forgotPass &&
        !location.pathname.includes(ROUTES.diamondVideo)
      ) {
        const isWorthy = location.pathname.includes('/worthy')
        const path = location.pathname.replaceAll('/worthy', '')
        history.replace({
          pathname: isWorthy ? `/worthy${ROUTES.login}` : ROUTES.login,
          state: { prevLocation: (isWorthy ? `/worthy${path}` : location.pathname) + location.search },
        })
      }
    })()
  }, [])

  useEffect(() => {
    const locIsWorthy = location.pathname.includes('/worthy')
    if (worthyState.isWorthy && !locIsWorthy) {
      const path = location.pathname.replaceAll('/worthy', '')
      history.replace({
        pathname: `/worthy${path}`,
      })
      dispatch(SetIsWorthy())
    } else if (locIsWorthy && !worthyState.isWorthy) {
      dispatch(SetIsWorthy())
    }
    if (locIsWorthy) {
      // eslint-disable-next-line no-console
      if (ENVIRONMENT === 'development') console.log('postMessageWorthy', location.pathname)
      window.parent.postMessage(location.pathname, '*')
    }
  }, [location])

  useLoadApp()

  if (location.pathname.includes(ROUTES.diamondVideo)) {
    return <Route path={[`${ROUTES.diamondVideo}/:id`, `/worthy${ROUTES.diamondVideo}/:id`]} component={DiamondVideo} />
  }

  if (!terms.confirmed && signIn.authorized) return <Route path={['*', '/worthy*']} component={Terms} />

  if (!signIn.authorized) {
    return (
      <Switch>
        <Route exact path={[ROUTES.login, `/worthy${ROUTES.login}`]} component={Login} />
        <Route path={[ROUTES.forgotPass, `/worthy${ROUTES.forgotPass}`]} component={ForgotPass} />
        <Route path={[ROUTES.successReset, `/worthy${ROUTES.successReset}`]} component={SuccessReset} />
        <Route path={[ROUTES.errorReset, `/worthy${ROUTES.errorReset}`]} component={ErrorReset} />
        <Route path={[ROUTES.newPass, `/worthy${ROUTES.newPass}`]} component={NewPassword} />
      </Switch>
    )
  }

  return (
    <LoggedInWrapper>
      <Switch>
        <Route exact path={[ROUTES.home, `/worthy${ROUTES.home}`]} component={Home} />
        <Route path={[ROUTES.dashboard, `/worthy${ROUTES.dashboard}`]} component={Dashboard} />
        <Route path={[ROUTES.userAccount, `/worthy${ROUTES.userAccount}`]} component={UserAccount} />
        {/* <Route path={ROUTES.adminPage} component={P403} /> */}
        {userData.isAssociateAdmin || userData.isExecutiveAdmin ? (
          <Route path={[ROUTES.adminPage, `/worthy${ROUTES.adminPage}`]} component={AdminPage} />
        ) : (
          <Route path={[ROUTES.adminPage, `/worthy${ROUTES.adminPage}`]} component={P403} />
        )}
        <Route path={[ROUTES.tenderResults, `/worthy${ROUTES.tenderResults}`]} component={TenderResults} />
        <Route path={[ROUTES.tenders, `/worthy${ROUTES.tenders}`]} component={ProductGallery} />
        <Route path={[`${ROUTES.c}/:tenderId`, `/worthy${ROUTES.c}/:tenderId`]} component={C} />
        <Route path='*' component={P404} />
      </Switch>
      <GlobalLoader />
    </LoggedInWrapper>
  )
}
