/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,jsx-a11y/control-has-associated-label,jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { DiamondCard, DiamondFilters, WatchesCard, JewelryCard } from '.'
import { GallerySelect } from '../../components/ui/galleryUi/GallerySelect'
import { AppStore } from '../../store/applicationState'
import { IconListSolid } from '../../svg/IconListSolid'
import { IconSearch } from '../../svg/IconSearch'
import { IconThLarge } from '../../svg/IconThLarge'
import { addNewParams, deleteParams } from '../../utils/helpers'
import { IconLikeGallery } from '../../svg/IconLikeGallery'
import { TOKEN, recMode } from '../../utils/consts'
import { DiamondLoader } from '../../components/ui/DiamondLoader'
import { IconStar } from '../../svg/IconStar'
import { IconBack } from '../../svg/IconBack'
import { IconSearchMobile } from '../../svg/IconSearchMobile'
import { IconSearchFilled } from '../../svg/IconSearchFilled'

interface GalleryCardsProps {
  setGalleryView: (value: string) => void
  isCatalog: boolean
  loading: boolean
  loadingItems: boolean
  loadingSearchItems: boolean
  setsearch: (value: string) => void
  setSort: (value: string) => void
  setSortBy: (value: string) => void
  favorites: boolean
  setFavorites: (value: boolean) => void
  recommendations: boolean
  setRecommendations: (value: boolean) => void
  subtitleCount: any
  sortBy: string
  sort: string
}

const debounce = (func: Function, wait: number) => {
  let timeout: number
  const debounced = (...args: any[]) => {
    clearTimeout(timeout)
    timeout = window.setTimeout(() => func(...args), wait)
  }
  debounced.cancel = () => clearTimeout(timeout)
  return debounced
}

export const GalleryCards: React.FC<GalleryCardsProps> = ({
  isCatalog,
  loading,
  loadingItems,
  loadingSearchItems,
  setsearch,
  setSort,
  setSortBy,
  favorites,
  setFavorites,
  recommendations,
  setRecommendations,
  subtitleCount,
  setGalleryView,
  sortBy,
  sort,
}) => {
  const recommendParam = new URLSearchParams(window.location.search).get('recommendations')

  const [activeFilter, setActiveFilter] = useState(new URLSearchParams(window.location.search).get('sort') || '')
  const [showFilters, setShowFilters] = useState(false)
  const [searchQuery, setSearchQuery] = useState(new URLSearchParams(window.location.search).get('search') || '')

  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isSelectOpen, setIsSelectOpen] = useState(true)

  const [isHidden, setIsHidden] = useState(false)
  const [isHiddenHeader, setIsHiddenHeader] = useState(false)

  const { gallery } = useSelector((store: AppStore) => store)
  const location = useLocation()

  const tenderId = gallery.data.tenderInfo?.tender_info.tender_id || 0

  useEffect(() => {
    if (new URLSearchParams(window.location.search).get('search')) {
      setSearchQuery(new URLSearchParams(window.location.search).get('search') || '')
    } else {
      setSearchQuery('')
    }

    if (new URLSearchParams(window.location.search).get('sort')) {
      setActiveFilter(new URLSearchParams(window.location.search).get('sort') || '')
    } else {
      setActiveFilter('')
    }
  }, [location])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setsearch(searchQuery)
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchQuery])

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (window.scrollY >= 700) {
        setIsHidden(true)
      } else {
        setIsHidden(false)
      }
      if (window.scrollY >= 2100) {
        setIsHiddenHeader(true)
      } else {
        setIsHiddenHeader(false)
      }
    }, 100)

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      handleScroll.cancel()
    }
  }, [])

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen)
    setIsSelectOpen(!isSelectOpen)
  }

  const galleryBody = gallery.data.tenderInfo ? (
    <div className='pb-16 text-sm'>
      {/* (lg >) */}
      <div
        className={`sort sticky ${
          sessionStorage.getItem(TOKEN.access) ? 'top-[73px]' : 'top-10'
        }  z-100 hidden lg:block`}
      >
        <div className='bg-white py-2 px-4 lg:flex space-y-3 border-t lg:space-y-0 items-center justify-between border-bluegray-100 shadow-sm'>
          <div className='flex items-center justify-between gap-x-10'>
            <div className='available-items-counter text-sm lg:text-sm text-bluegray-500 font-circa uppercase font-semibold text-center lg:text-right'>
              {subtitleCount()}
            </div>

            <button
              type='button'
              onClick={() => {
                setFavorites(!favorites)
                addNewParams('favorites', `${!favorites}`)
              }}
              className={`favorites-filter rounded-md select-none focus:outline-none px-4 py-2 border border-bluegray-100  text-sm p-0 hover:bg-bluegray-100 ${
                favorites && 'bg-bluegray-100'
              }`}
            >
              <span className='text-red-400'>
                <IconLikeGallery isActive={false} bgColor='text-red-400' />
              </span>
            </button>
            {(gallery.data.atLeastOneRecommended || recommendParam || recMode) && (
              <button
                type='button'
                onClick={() => {
                  setRecommendations(!recommendations)
                  addNewParams('recommendations', `${!recommendations}`)
                }}
                className={`recommendations-filter rounded-md select-none focus:outline-none px-4 py-2 border border-bluegray-100 text-sm p-0 hover:bg-bluegray-100 ${
                  recommendations && 'bg-bluegray-100'
                }`}
              >
                <span className='text-yellow-400'>
                  <IconStar isActive />
                </span>
              </button>
            )}
            {gallery.data.tenderInfo?.tender_info.category_id === 1 && (
              <button
                type='button'
                onClick={() => {
                  setGalleryView('list')
                  deleteParams('sort')
                  addNewParams('view', 'list')
                  if (searchQuery) addNewParams('search', searchQuery)
                  if (favorites) addNewParams('favorites', `${favorites}`)
                  if (recommendations) addNewParams('recommendations', `${recommendations}`)
                  setSort('DESC')
                  setSortBy('weight')
                }}
                className='view-switcher rounded-md select-none focus:outline-none px-4 py-2 border border-bluegray-100  hidden md:flex hover:bg-bluegray-100'
              >
                <span className='mr-2 text-sky-600'>
                  <IconThLarge />
                </span>
                <span className='text-sky-600'>
                  <IconListSolid />
                </span>
              </button>
            )}
          </div>

          <div className='space-x-4 flex items-center justify-between'>
            <div className='sort-by w-full lg:w-auto '>
              <GallerySelect
                tenderCategory={gallery.data.tenderInfo?.tender_info.category_id}
                activeFilter={activeFilter}
                isCatalog={isCatalog}
                sortBy={sortBy}
                sort={sort}
                setSort={setSort}
                setSortBy={setSortBy}
                setActiveFilter={setActiveFilter}
              />
            </div>
          </div>

          <label htmlFor='search-input' className='flex space-x-3'>
            <input
              id='search-input'
              type='text'
              value={searchQuery}
              onChange={(event) => {
                setSearchQuery(event.target.value)
                addNewParams('search', event.target.value)
              }}
              placeholder='Search'
              className='border border-bluegray-200 rounded p-1 pr-8 ring-0 outline-none focus:ring-0 focus:outline-none ring-transparent focus:border-bluegray-200 text-xs md:text-sm w-full '
            />

            <div className='text text-bluegray-400 rounded-md select-none focus:outline-none cursor-pointer flex items-center'>
              <IconSearch />
            </div>
          </label>
        </div>
      </div>

      {/* ( < lg) */}
      <div
        className={`sort sticky ${
          sessionStorage.getItem(TOKEN.access) ? 'top-[73px]' : 'top-10'
        } z-100 block lg:hidden ${isHiddenHeader ? '!top-0 z-[160]' : ''}`}
      >
        <div className='bg-white py-2 px-4 h-[49px] flex space-y-3 border-t items-center justify-between border-bluegray-100 shadow-sm'>
          <div className='flex items-center justify-between w-full gap-x-4'>
            {isSelectOpen ? (
              <div className={`${isSelectOpen ? 'flex-grow' : 'w-0 overflow-hidden'}`}>
                <GallerySelect
                  tenderCategory={gallery.data.tenderInfo?.tender_info.category_id}
                  activeFilter={activeFilter}
                  isCatalog={isCatalog}
                  sortBy={sortBy}
                  sort={sort}
                  setSort={setSort}
                  setSortBy={setSortBy}
                  setActiveFilter={setActiveFilter}
                />
              </div>
            ) : (
              <div
                className='text p-[3px] text-bluegray-400 border border-bluegray-100 rounded-md select-none focus:outline-none cursor-pointer flex items-center'
                onClick={handleSearchClick}
              >
                <IconBack />
              </div>
            )}
            <div className={` ${isSearchOpen ? 'w-full' : ''}`}>
              <div className={`flex items-center`}>
                {isSearchOpen && (
                  <div className='relative w-full items-center'>
                    <input
                      id='search-input'
                      type='text'
                      value={searchQuery}
                      onChange={(event) => {
                        setSearchQuery(event.target.value)
                        addNewParams('search', event.target.value)
                      }}
                      placeholder='Search'
                      className='border text-base pl-8 border-bluegray-200 rounded-md p-[6px] pr-8 ring-0 outline-none focus:ring-0 focus:outline-none ring-transparent focus:border-bluegray-200 w-full'
                    />
                    <div className='search-icon absolute left-2 top-1/2 transform -translate-y-1/2'>
                      <IconSearchMobile />
                    </div>
                  </div>
                )}
                {!isSearchOpen && (
                  <div
                    className={`text text-bluegray-400 rounded-md select-none focus:outline-none cursor-pointer flex items-center`}
                    onClick={handleSearchClick}
                  >
                    {searchQuery ? <IconSearchFilled /> : <IconSearchMobile />}
                  </div>
                )}
              </div>
            </div>

            <button
              type='button'
              onClick={() => {
                setFavorites(!favorites)
                addNewParams('favorites', `${!favorites}`)
              }}
              className={`favorites-filter rounded-md select-none focus:outline-none px-2 py-1 border border-bluegray-100 text-sm p-0 hover:bg-bluegray-100 ${
                favorites && 'bg-bluegray-100'
              }`}
            >
              <span className='text-red-400'>
                <IconLikeGallery isActive={false} bgColor='text-red-400' />
              </span>
            </button>
            {(gallery.data.atLeastOneRecommended || recommendParam || recMode) && (
              <button
                type='button'
                onClick={() => {
                  setRecommendations(!recommendations)
                  addNewParams('recommendations', `${!recommendations}`)
                }}
                className={`recommendations-filter rounded-md select-none focus:outline-none px-2 py-1 border border-bluegray-100 text-sm p-0 hover:bg-bluegray-100 ${
                  recommendations && 'bg-bluegray-100'
                }`}
              >
                <span className='text-yellow-400'>
                  <IconStar isActive />
                </span>
              </button>
            )}
          </div>
        </div>
        <div
          className={`bg-white py-2 px-4 border-t border-bluegray-100 fade opacity-100 ${isHidden ? 'notVisible' : ''}`}
        >
          <div className='available-items-counter text-sm text-bluegray-500 font-circa uppercase font-semibold text-left'>
            {subtitleCount()}
          </div>
        </div>
      </div>

      <div className='flex w-full md:px-6 mt-6'>
        {showFilters && <DiamondFilters showFilters={showFilters} setShowFilters={setShowFilters} />}

        <div className='flex-grow'>
          <div className='flex flex-wrap justify-center'>
            {!loadingItems ? (
              <>
                {gallery.data.tenderItems?.length ? (
                  gallery.data.tenderItems?.map((item) => {
                    switch (item.category_id) {
                      case 1:
                        return <DiamondCard item={item} key={item.item_id} isCatalog={isCatalog} tenderId={tenderId} />

                      case 2:
                        return <JewelryCard item={item} key={item.item_id} isCatalog={isCatalog} tenderId={tenderId} />

                      case 3:
                        return <WatchesCard item={item} key={item.item_id} isCatalog={isCatalog} tenderId={tenderId} />

                      default:
                        return null
                    }
                  })
                ) : (
                  <div className='empty-search-result px-4'>No items match your criteria.</div>
                )}

                {loadingSearchItems ? (
                  <div className='fixed w-full h-full bg-opacity-40 bg-gray-900 -mt-6 flex justify-center items-center'>
                    <div className='flex items-center mb-48'>
                      <DiamondLoader />
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='px-4 py-2'>Tender not found</div>
  )

  return loading ? null : galleryBody
}
